import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Hyperextensions 3×12`}</p>
    <p>{`Planks 3×1:00`}</p>
    <p>{`then,`}</p>
    <p>{`KB SDHP’s 4×12`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`8-Calorie Ski Erg`}</p>
    <p>{`10-DB Deadlifts (50/35’s)`}</p>
    <p>{`12-GHD SItups`}</p>
    <p>{`14-Box Jumps (24/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule starts this week!  Evening CrossFit classes
are now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new Ville Bootcamp started this week as well!  These classes
will be Tues-Friday at 9:30am.  The rest of this month is free for non
members so spread the word if you know of anyone that may want to try it
out. This class is included in your unlimited membership. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      